import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './css/TextEditor.css';

// Register undo/redo icons
const icons = Quill.import('ui/icons');
icons['undo'] = `<svg viewBox='0 0 18 18'>
  <polygon class='ql-fill ql-stroke' points='6 10 4 12 2 10 6 10' />
  <path class='ql-stroke' d='M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9' />
</svg>`;
icons['redo'] = `<svg viewBox='0 0 18 18'>
  <polygon class='ql-fill ql-stroke' points='12 10 14 12 16 10 12 10' />
  <path class='ql-stroke' d='M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5' />
</svg>`;

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'code'],
      ['clean'],
      ['undo', 'redo'], // Add undo/redo buttons to toolbar
    ],
    handlers: {
      undo: function (value: boolean) {
        // @ts-ignore
        this.quill.history.undo();
      },
      redo: function (value: boolean) {
        // @ts-ignore
        this.quill.history.redo();
      },
    },
  },
  history: {
    // Enable history module
    delay: 1000,
    maxStack: 100,
    userOnly: true,
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'code',
];

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: OnChangeHandler;
};

export const TextEditor: React.FC<Props> = ({ value, onChange, placeholder }) => {
  const [firstChange, setFirstChange] = useState(true);

  const cleanText = (text?: string): string | void => {
    if (!text) return;
    text = text.replace(/\s+/g, ' ').trim();
    const temporal = document.createElement('div');
    temporal.innerHTML = text;
    return temporal.textContent || temporal.innerText || '';
  };

  const handleEditorChange = (content: string): void => {
    if (firstChange) {
      if (cleanText(content) === cleanText(value)) return;
      setFirstChange(false);
    }
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <ReactQuill
      id='TextEditor'
      theme='snow'
      value={value || ''}
      modules={modules}
      formats={formats}
      onChange={handleEditorChange}
      placeholder={placeholder}
    />
  );
};

export default TextEditor;
